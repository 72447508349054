<template>
    <div>
        <router-view></router-view>
    </div>
</template>
<script>
import {mapActions } from "vuex";
import {getVenderProfile,getUserData,getVendorExh} from '@/api'

export default {
    name:'Vendor',
    data(){
        return{
        }
    },
    mounted() {
        if(localStorage.hasOwnProperty('token')){
            if(localStorage.getItem('token')){
                this.getUderDataInfo()
                if(localStorage.getItem('type')=='seller'){
                    //403 data: null
                    //message: "Unactive seller can't execute."
                    //status: 403
                    this.getVenderProfileInfo()
                }
            }
        }
    },
    methods: {
        ...mapActions(['setVendor','setLogin','setExhData',"clearSeller","setLogout"]),
        async getVenderProfileInfo(){
            let vm = this
            let errormsg = '';
            let res = await getVenderProfile().catch(function (error) {
                  if (error.response) {
                    errormsg = error.response.data.message;
                    return error.response.status
                  }
            });
            if(res == 401 || res == 422 || res == 403 ){
                this.$message({
                    showClose: true,
                    message: `${errormsg}.Please Contact service.`,
                    type: 'warn',
                    duration: 2000,
                    onClose: function(){
                        vm.logout('true')
                    }
                });
            }
            this.setVendor(res.data.data)
            this.getExh()
            //this.$router.push({name:'VendorHome'})
        },
        logout(type){
            localStorage.removeItem("token");
            this.clearSeller();
            this.setLogout();
            this.$router.push({name:'LandingPage'})
        },
        async getUderDataInfo(){
            let res = await getUserData()
            this.setLogin(res.data.data)
        },
        async getExh(){
            let res = await getVendorExh()
            this.setExhData(res.data.data)
        },
    },
}
</script>
<style lang="scss" scoped>

</style>